<template>
    <div v-if="showModal" :class="['modal',showModal?'modal-open':'']" style="display: block" @keyup.esc="onClose">
        <div class="modal-dialog" role="document">
            <div class="modal-content">

                <div class="modal-header">
                    <h3 class="modal-content__title">MEHR FILTER</h3>

                    <button tabindex="0" type="button" class="close" ref="closeBtn" data-dismiss="modal" aria-label="Close" @click="onClose">
                        <span class="material-icons" aria-hidden="true">close</span>
                    </button>
                </div>

                <div class="filters text-left">
                    <WBSelectField class="mb15" v-for="(item, index) in filters" :key="item.field_type_id" :name="item.label" :selectedFilters="selectedFilters" :field-type-id="item.field_type_id" :values="item.options ? item.options : []" @on-select="bildungsFilterChanged" :isMultiple="true" />
                </div>

                <div class="d-flex justify-content-around">
                    <button class="btn btn-link" @click="onClose">Abbrechen</button>
                    <button class="btn btn-secondary" @click="filteringFinished">{{contentCount}} Ergebnisse anzeigen</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "WBMoreFilters",
        components: {
            WBSelectField: () => import('@/components/controls/WBSelectField.vue'),
        },
        props: {
            showModal: {
                type: Boolean,
                default: false,
            },
            filters: {
                type: Array,
                required: true,
            },
            selectedFilters: {
                type: Map,
                default: new Map(),
            },
            contentCount: {
                tyle: Number,
                default: 0
            }
        },
        data() {
            return {
                tempFilters: new Map,
            }
        },
        watch: {
            showModal(newValue){
              this.$nextTick(() => {
                if(newValue == true){
                    this.$refs.closeBtn.focus();
                }
            });
          }
      },
      mounted() {
        /*fill the tempFilters with the values of the "real" Filter */
        this.tempFilters = new Map([...this.tempFilters,...this.selectedFilters]);
    },
    methods: {
        onClose() {
            this.$emit('on-close');
        },
        filteringFinished(){
            var filterForMap = new Map([...this.tempFilters,...this.selectedFilters]);
            this.$emit('filteringFinished',filterForMap);
        },
        bildungsFilterChanged(fieldTypeId,values){
            this.tempFilters.set(fieldTypeId,values);
            this.$emit('getNumberOfResults',this.tempFilters);
        }
    }
};
</script>
